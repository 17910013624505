import { graphql, Link } from 'gatsby'
import React from 'react'
import { Billboard, Button, SEO } from '@renderbus/common/components'
import CaseWrapper from '../molecules/client-works/case-wrapper'
import FullPage from '../molecules/client-works/full-page/index'
import {
  BannerContainer,
  BannerSubTitle,
  BannerTitle,
  CompanyContainer,
  CompanyIcons,
} from './client-works.atom'

import { useMedia } from 'use-media'
import companyMobile1 from '../images/client-works/companyMobile1.png'
import companyMobile2 from '../images/client-works/companyMobile2.png'
import companyMobile3 from '../images/client-works/companyMobile3.png'
import company1 from '../images/client-works/companys1.png'
import company2 from '../images/client-works/companys2.png'
import caseDatas from '../molecules/client-works/caseDatas'
import Layout from '../molecules/layout'

class ClientWorks extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { caseArray: [], fullPageLength: 0 }
  }
  componentDidMount() {
    let data = this.props.data
    let caseArray = [
      new caseDatas(
        '流浪地球2',
        '根据刘慈欣同名小说改编，是《流浪地球》的前传，以提出计划将建造1万座行星发动机的时代为背景，讲述了“太阳危机”即将来袭，人类文明的延续将面临巨大挑战。人类决定开启流浪地球计划，带着地球离开太阳系，寻找新家园的故事。',
        data.case1.childImageSharp.fluid,
        data.case1Mobile.childImageSharp.fluid,
      ),
      new caseDatas(
        '熊出没·逆转时空',
        '光头强是一名普通程序员，却常梦见陌生的森林和两头狗熊。直到他跟上司出访大客户，终于想起：他原是森林的小导游，偶然得到一次重新选择人生的机会。为了挽救旧时间线里的熊大熊二，光头强开始一场奇妙的时空冒险。',
        data.case2.childImageSharp.fluid,
        data.case2Mobile.childImageSharp.fluid,
      ),
      new caseDatas(
        '三体',
        '该剧根据科幻作家刘慈欣同名小说《三体》改编，讲述了地球基础科学研究遭遇异常扰动，引发科学界惶恐动荡，纳米物理学家汪淼与刑警史强联手调查，共同揭开了地外未知文明“三体”世界的神秘面纱，并随全人类一道与即将入侵的三体人展开博弈的故事。',
        data.case3.childImageSharp.fluid,
        data.case3Mobile.childImageSharp.fluid,
      ),
      new caseDatas(
        '林林、小卖部',
        '《中国奇谭》之《林林》、《小卖部》由瑞云独家渲染。《中国奇谭》由八个植根于中国传统文化的独立的故事组成，从北国风光到胡同巷里、从未来宇宙到乡土人间，纵览古今、展望未来，故事内容涵盖乡土眷恋、科技幻想、生命母题和人性思考，展示着中式想象力、承载着中国民族文化与哲学。',
        data.case4.childImageSharp.fluid,
        data.case4Mobile.childImageSharp.fluid,
      ),
      new caseDatas(
        '长津湖之水门桥',
        '该片以抗美援朝战争第二次战役中的长津湖战役后期的水门桥战役为背景，讲述志愿军第七连战士们在结束了新兴里和下碣隅里的战斗之后，又接到了更艰巨的阻击任务的故事。',
        data.case5.childImageSharp.fluid,
        data.case5Mobile.childImageSharp.fluid,
        ['2022年度票房榜冠军', '第十七届中国长春电影节“金鹿奖”最佳影片奖'],
      ),
      new caseDatas(
        '长津湖',
        '《长津湖》以抗美援朝战争第二次战役中的长津湖战役为背景，讲述了一段波澜壮阔的历史：71年前，中国人民志愿军赴朝作战，在极寒严酷环境下，东线作战部队凭着钢铁意志和英勇无畏的战斗精神一路追击，奋勇杀敌，扭转了战场态势，打出了军威国威。',
        data.case6.childImageSharp.fluid,
        data.case6Mobile.childImageSharp.fluid,
      ),
      new caseDatas(
        '哪吒之魔童降世',
        '首映期间创动画电影最快破亿纪录、动画电影首日最高票房纪录、国产动画电影单周票房新纪录...“我是小妖怪，逍遥又自在。杀人不眨眼，吃人不放盐。”黑眼圈、小雀斑、鲨鱼牙、厌世脸...颠覆传统莲花化身，没有一丝仙气儿的熊孩子“丑哪吒“，在电影点映期间从无人问津到口碑爆棚，让人看到国漫崛起的新标准，新希望。',
        data.case7.childImageSharp.fluid,
        data.case7Mobile.childImageSharp.fluid,
        ['IMAX 3D首部国产动画电影刷新中国动画电影票房纪录'],
      ),
      new caseDatas(
        '流浪地球',
        '上映11天获382万美元票房，登顶近五年中国电影北美票房榜， 刷新近五年中国电影Top5排名。国内总票房已经突破46亿大关，在内地总票房排名位列第2，具有“科幻电影里程碑”的意义。',
        data.case8.childImageSharp.fluid,
        data.case8Mobile.childImageSharp.fluid,
        ['9届北京国际电影节 天坛奖', '第26届北京大学生电影节 最佳影片奖'],
      ),
      new caseDatas(
        '三个机器人',
        '2019高分动画短片集《爱，死亡与机器人》之《三个机器人》由瑞云独家渲染。由《死侍》导演Tim Miller与知名导演David Fincher两大鬼才联手监制，号召来自世界各地的动画团队制作，选集风格多变，视觉效果更是为人称道。',
        data.case9.childImageSharp.fluid,
        data.case9Mobile.childImageSharp.fluid,
      ),
      new caseDatas(
        'Mr. Hublot',
        '作为一家在法国的3D动画电影的小型工作室，我们需要大量的运算力来展现我们的创意，这需要比工作室多得多的电脑，在没有任何外界帮助下，计算机渲染这部分难以完成，最终我们找到了瑞云。---短片导演 劳伦特维茨(Laurent Witz)',
        data.case10.childImageSharp.fluid,
        data.case10Mobile.childImageSharp.fluid,
        ['第86届奥斯卡金像奖'],
      ),
      new caseDatas(
        '战狼2',
        '瑞云提供的传输方案非常便利，上传下载都挺方便，我们这边只要存储到本地，建立一个帐号给到对方，对方就可以上直接访问。多国沟通，素材交互，安全方便，价格不低，但绝对值！——后期制作总监小刚',
        data.case11.childImageSharp.fluid,
        data.case11Mobile.childImageSharp.fluid,
        ['大众电影百花奖', '中国电影华表奖'],
      ),
    ]
    let fullPageLength = 0
    fullPageLength = caseArray.length + 1
    this.setState({
      caseArray: caseArray,
      fullPageLength: fullPageLength,
    })
  }
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    const { caseArray, fullPageLength } = this.state
    let windowHeight = '100vh'
    if (typeof window !== 'undefined') {
      windowHeight = `${window.innerHeight}px`
    }
    return (
      <Layout location={location} topThreeShare={topThreeShare} isWithoutFooter={true}>
        <SEO
          title='瑞云渲染案例,瑞云客户如繁星般璀璨-Renderbus云渲染农场'
          keywords='瑞云渲染案例'
          description='瑞云渲染案例,包括流浪地球2,熊出没·伴我"熊芯",三体,林林,小卖部,长津湖之水门桥,长津湖,哪吒之魔童降世,流浪地球,三个机器人,哈布洛先生,战狼2等'
        />
        <FullPage
          caseArray={caseArray}
          childrenLength={fullPageLength}
          footerInfo={topThreeShare}
          delay={700}
        >
          <BannerContainer windowHeight={windowHeight}>
            <BannerTitle>影视/视效/动画/效果图云渲染</BannerTitle>
            <BannerSubTitle>瑞云渲染客户如繁星般闪耀</BannerSubTitle>
            <Link to='/vfx.html'>
              <Button>更多案例</Button>
            </Link>

            <CompanyContainer>
              <ScreenWidth>
                {isMobile => (
                  <>
                    {!isMobile ? (
                      <>
                        <CompanyIcons src={company1} alt='更多案例1' marginTop='88px' />
                        <CompanyIcons src={company2} alt='更多案例2' marginTop='74px' />
                      </>
                    ) : (
                      <>
                        <CompanyIcons src={companyMobile1} alt='更多案例1' marginMobileTop='65px' />
                        <CompanyIcons src={companyMobile2} alt='更多案例2' marginMobileTop='40px' />
                        <CompanyIcons src={companyMobile3} alt='更多案例3' marginMobileTop='40px' />
                      </>
                    )}
                  </>
                )}
              </ScreenWidth>
            </CompanyContainer>
            <Billboard
              fluid={[
                data.bannerMobile.childImageSharp.fluid,
                {
                  ...data.banner.childImageSharp.fluid,
                  media: `(min-width:780px)`,
                },
              ]}
            />
          </BannerContainer>
          {caseArray.map((caseData, index) => (
            <CaseWrapper
              key={caseData.title}
              title={caseData.title}
              rewards={caseData.rewards}
              info={caseData.info}
              background={caseData.background}
              bgMobile={caseData.bgMobile}
            />
          ))}
        </FullPage>
      </Layout>
    )
  }
}

export default ClientWorks

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/client-works/bg.png/" }) {
      ...fluidImage
    }
    bannerMobile: file(relativePath: { regex: "/client-works/bg_mobile.png/" }) {
      ...fluidImage
    }
    company1: file(relativePath: { regex: "/client-works/companys1.png/" }) {
      ...fluidImage
    }
    company2: file(relativePath: { regex: "/client-works/companys2.png/" }) {
      ...fluidImage
    }
    case1: file(relativePath: { regex: "/client-works/case01.png/" }) {
      ...fluidImage
    }
    case2: file(relativePath: { regex: "/client-works/case2.png/" }) {
      ...fluidImage
    }
    case3: file(relativePath: { regex: "/client-works/case3.png/" }) {
      ...fluidImage
    }
    case4: file(relativePath: { regex: "/client-works/case4.png/" }) {
      ...fluidImage
    }
    case5: file(relativePath: { regex: "/client-works/case5.png/" }) {
      ...fluidImage
    }
    case6: file(relativePath: { regex: "/client-works/case6.png/" }) {
      ...fluidImage
    }
    case7: file(relativePath: { regex: "/client-works/case7.png/" }) {
      ...fluidImage
    }
    case8: file(relativePath: { regex: "/client-works/case8.png/" }) {
      ...fluidImage
    }
    case9: file(relativePath: { regex: "/client-works/case9.png/" }) {
      ...fluidImage
    }
    case10: file(relativePath: { regex: "/client-works/case10.png/" }) {
      ...fluidImage
    }
    case11: file(relativePath: { regex: "/client-works/case11.png/" }) {
      ...fluidImage
    }
    case1Mobile: file(relativePath: { regex: "/client-works/case1_mobile.png/" }) {
      ...fluidImage
    }
    case2Mobile: file(relativePath: { regex: "/client-works/case2_mobile.png/" }) {
      ...fluidImage
    }
    case3Mobile: file(relativePath: { regex: "/client-works/case3_mobile.png/" }) {
      ...fluidImage
    }
    case4Mobile: file(relativePath: { regex: "/client-works/case4_mobile.png/" }) {
      ...fluidImage
    }
    case5Mobile: file(relativePath: { regex: "/client-works/case5_mobile.png/" }) {
      ...fluidImage
    }
    case6Mobile: file(relativePath: { regex: "/client-works/case6_mobile.png/" }) {
      ...fluidImage
    }
    case7Mobile: file(relativePath: { regex: "/client-works/case7_mobile.png/" }) {
      ...fluidImage
    }
    case8Mobile: file(relativePath: { regex: "/client-works/case8_mobile.png/" }) {
      ...fluidImage
    }
    case9Mobile: file(relativePath: { regex: "/client-works/case9_mobile.png/" }) {
      ...fluidImage
    }
    case10Mobile: file(relativePath: { regex: "/client-works/case10_mobile.png/" }) {
      ...fluidImage
    }
    case11Mobile: file(relativePath: { regex: "/client-works/case11_mobile.png/" }) {
      ...fluidImage
    }
    sharePosts: allContentfulShare(
      limit: 3
      sort: { fields: [legacyDate, createdAt], order: [DESC, DESC] }
    ) {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`
export const ScreenWidth = ({ children }) => {
  const isMobile = useMedia({ maxWidth: 780 })
  return children(isMobile)
}
